import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { AuthService } from 'src/app/services/auth.service';
import { appConfig } from 'src/config';
import { Subscription } from 'rxjs';
declare global {
  interface Window {
    callSuccess: boolean;
    errorMessage: any;
  }
}
@Component({
  selector: 'app-benifit-plan',
  templateUrl: './benifit-plan.component.html',
  styleUrls: ['./benifit-plan.component.scss']
})
export class BenifitPlanComponent implements OnInit {
  list: any = [];
  LOGIN_KEY = "login_status";
  Form: FormGroup;
  designation: any;
  contributionAmount: any;
  matchingType: any;
  monthTillHundred: any;
  estimatedMonth: any;
  contributionCap: any;
  matchingPercent: any;
  fixed: boolean = true;
  contributionsTillVesting: any;
  today: any;
  dateOfEnrollment: any;
  logo: any;
  private queryParamsSubscription: Subscription
  constructor(protected dec_enc: DecEncService, protected mainService: ApiService, public formbuilder: FormBuilder, private datePipe: DatePipe, private translate: TranslateService, private authService: AuthService, private route: ActivatedRoute,) {
    localStorage.clear();
    this.startCall();
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      const token = params['t'];
      localStorage.setItem('token', token);
      const password = params['p'];
      localStorage.setItem('employee_report_token', password);
    });
  }

  ngOnInit(): void {
    this.getDesignation();
    this.Form = this.formbuilder.group({
      designation: [],
      date: [],
    });
    this.today = new Date();
    this.getAll();
    this.getProfile();
  }

  getAll() {
    let body = {};
    let url = 'home/planDetails'
    this.authService.postData(url, {}).then(res => {
      if (res.statusCode === 200) {
        let result = this.authService.decryption(res.data);
        console.log('plan~', result);
        
        this.list = result?.plan_details;
        //this.designation = this.list.funds_groups_employees[0].funds_group.group_name;
        let salary = this.list.user.salary;
        let contributionPercent = this.list.employee_matching.contribution_percent;
        this.contributionAmount = (salary * contributionPercent) / 100;
        this.contributionCap = this.list.funds_groups_employees[0].funds_group.max_cap;
        this.matchingType = this.capitalizeFirstLetter(this.list.funds_groups_employees[0].funds_group.type);
        if (this.matchingType == 'Fixed') {
          this.matchingPercent = this.list.funds_groups_employees[0].funds_group.matching;
          this.fixed = true;
        }
        else {
          this.matchingPercent = this.list.funds_groups_employees[0].funds_group.initial_matching;
          this.fixed = false;
        }
        this.monthTillHundred = this.list.funds_groups_employees[0].funds_group.months;
        let totalContributions = this.list.total_contributions;
        let vested_contributions = this.list.vested_contributions;
        let conributiosDifference = totalContributions - Number(vested_contributions);
        let vestingPeriod = this.list.funds_groups_employees[0].funds_group.vesting_period;
        this.contributionsTillVesting = vestingPeriod - conributiosDifference;
        this.today.setMonth(this.today.getMonth() + this.contributionsTillVesting);
        let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short' };
        this.estimatedMonth = new Intl.DateTimeFormat('en-US', options).format(this.today);
        window.callSuccess = true;
      }
      else {
        window.callSuccess = false;
        window.errorMessage = res.error.message;
      }
    });
  }

  getDesignation() {
    let url = 'employee/details';
    this.authService.postData(url, {}).then(res => {
      if (res.statusCode === 200) {
        let result = this.authService.decryption(res.data);
        this.designation = result.employee.position;
        this.dateOfEnrollment = result.employee.created_at;
        this.dateOfEnrollment = this.datePipe.transform(this.dateOfEnrollment, 'dd-MM-yyyy');
        this.Form.controls['designation'].setValue(this.designation);
        this.Form.controls['date'].setValue(this.dateOfEnrollment);
      }
    })
  }

  getProfile(){
    let url = 'viewProfile';
    this.authService.postData(url, {}).then(res => {
      if (res.statusCode === 200) {
        let result = this.authService.decryption(res.data);
       console.log('profile~', result);
       let file_url = appConfig.file_url;
       this.logo = file_url + result.user.company_logo;
       console.log(this.logo);
      }
    })
  }

  startCall() {
    localStorage.removeItem(this.LOGIN_KEY);
    this.authService.getStart("start").then((res) => {
      if (res.statusCode == 200) {
        localStorage.setItem("password_token", res.data.access_token);
        return;
      }
    });
  }

  capitalizeFirstLetter(word: string): string {
    if (!word) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }
}