<h3 class="form-heading">Self-Onboarding</h3>

<form [formGroup]="Form">
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
            <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field mt-2" fxFlexAlign="center"
                appearance="outline" style="width: 100%;">
                <mat-label>Id Number</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="iqama_id">
            </mat-form-field>
        </div>
        <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
            <!-- <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field mt-2" fxFlexAlign="center"
                appearance="outline" style="width: 100%;">
                <mat-label>Company Name</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="name">
            </mat-form-field> -->
            <div fxLayoutAlign="end center" style="margin-top: 10px;">
                <button class="btn-submit mt-2" mat-raised-button (click)="submit()">Submit</button>
            </div>
        </div>
    </div>
</form>