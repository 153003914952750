import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { actionButton } from "src/app/lib/table/table.component";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { PaginationService } from "src/app/services/pagination.service";
import { ProfileService } from "src/app/services/profile.service";

@Component({
  selector: "app-reallocate-fund",
  templateUrl: "./reallocate-fund.component.html",
  styleUrls: ["./reallocate-fund.component.scss"],
})
export class ReallocateFundComponent implements OnInit {
  risk: any = "Low";
  list: any = [];
  pages: any;
  perPage: number = 10;
  index: any = 1;
  recomended_risk: any;
  currentPage: any = 1;
  count: any;
  items: any;
  fundlist: any;
  employee_name: any;
  acpt: any = "assets/ic_verified_user.png";
  acpt_dis: any = "assets/ic_verified_user_24px.png";
  rejct: any = "assets/ic_cancel_24px.png";
  rejct_dis: any = "assets/ic_cancel.png";
  from: any;
  select_fund: any;
  searchTimer: any;
  search: any;
  select_fund_id: any;
  sort_by: any = null;
  sort_order = "";
  sortData: { value: any; key: string }[];
  colHeader = {
    id: "Reallocation ID",
    company_name: "Employer Name",
    from_portfolio: "From Portfolio",
    to_portfolio: "To Portfolio",
    statusToShow: "Status",
    created_at: "Date of Request",
    actions: "Actions",
  };

  columnTypes = {
    created_at: "date",
    updated_at: "date",
    actions: "actionsSeperate",
    statusToShow: "statusToShow",
  };

  headingData: string = "Portfolio Allocation Detail";
  headerProps = {
    heading: "Portfolio Allocation Detail",
    hasSearch: true,
    hasButton: false,
    ActionButtons: [],
    filterArray: [
      {
        label: "Sort by",
        type: "filter_list",
        key: "sort_by",
        visibility: false,
        selected: -1,
        options: [
          {
            value: -1,
            label: "All",
            key: "1",
          },
          {
            value: "1",
            label: "Completed",
            key: "1",
          },
          {
            value: "0",
            label: "Pending",
            key: "0",
          },
          {
            value: "2",
            label: "Rejected",
            key: "2",
          },
          {
            value: "3",
            label: "UnderProcess",
            key: "3",
          },
        ],
      },
    ],
  };

  actions: actionButton[] = [
    {
      label: "Cancel Request",
      type: "delete",
      visibility: true,
      isCustom: true,
      color: "#EE5D50",
      background: "rgba(238, 93, 80, 0.05)",
      source: "assets/table-deactivate.svg",
    },
  ];
  paginations = {
    page: 1,
    pages: null,
    per_page: null,
    count: 0,
  };

  constructor(
    public tr: TranslationService,
    protected dialog: MatDialog,
    protected mainService: ApiService,
    public dec_enc: DecEncService,
    protected paginationService: PaginationService,
    public alertService: AlertService,
    protected prService: ProfileService
  ) {
    this.employee_name = this.prService.user.name;
  }

  ngOnInit(): void {
    this.getFundsReallocations();
    this.onCheckAllocatedFund();
  }

  getFundsReallocations() {
    let count = 1;
    if (this.paginations.per_page != null) {
      count = this.paginations.per_page * (this.paginations.page - 1) + 1;
    }
    let data = {
      search: this.search ? this.search : null,
      status: this.sort_by != -1 && this.sort_by != null ? this.sort_by : null,
    };
    let enc_payload = this.dec_enc.encryption(data);
    let url = `funds/re_allocations?per_page=${this.paginations.per_page}&sort_order=DESC&page=${this.paginations.page}`;
    this.mainService
      .postData(url, { enc_payload })
      .then((res) => {
        if (res.statusCode == 200) {
          let result = this.dec_enc.decryption(res.data);
          this.list = result.re_allocations;
          this.paginations = result.pagination;
          this.afterList();
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  acceptReject(id, val) {
    this.alertService
      .alertAsk(
        "Confirmation",
        "Are you sure you want to cancel this request?",
        "yes",
        "no",
        false
      )
      .then((res) => {
        if (res) {
          let data = {
            id: id,
            status: "2",
          };
          let enc_payload = this.dec_enc.encryption(data);
          this.mainService
            .postData("funds/update_allocation_request", { enc_payload })
            .then((res) => {
              if (res.statusCode == 200) {
                this.getFundsReallocations();
                return;
              }
              this.alertService.alert("Error", "You have already rejected.");
            })
            .catch((error) => {
              this.alertService.alert("Error", this.tr.translation.serverError);
            });
        }
      });
  }

  onSelectFund(id, risk) {
    if (risk == this.select_fund && id == this.select_fund_id) {
      this.alertService.alert(
        this.tr.translation.alert,
        "You already have selected this fund"
      );
    } else {
      this.alertService
        .alertAsk(
          "Confirmation",
          "Please note that reallocating your portfolio will temporarily disable your contributions until the banking provider completes the process. Would you like to proceed with this action?",
          "yes",
          "no",
          false
        )
        .then((res) => {
          if (res == true) {
            let enc_payload = this.dec_enc.encryption({ fund_id: id });
            this.mainService
              .postData("funds/re_allocate", { enc_payload })
              .then((resp) => {
                if (resp.statusCode == 200) {
                  this.alertService.alert(
                    "Success",
                    "The portfolio reallocation request has been sent to fund manager for approval."
                  );
                  this.getFundsReallocations();
                  return;
                }
                this.alertService.alertAr("Error", resp);
              })
              .catch((error) => {
                this.alertService.alert("Error", error.message);
              });
          }
        });
    }
  }

  getAllavailableFunds() {
    this.mainService
      .postData("funds/available", {})
      .then((res) => {
        if (res.statusCode == 200) {
          let result = this.dec_enc.decryption(res.data);
          this.fundlist = result.funds;
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  onCheckAllocatedFund() {
    this.mainService
      .postData("funds/allocated", {})
      .then((res) => {
        if (res.statusCode == 200) {
          let result = this.dec_enc.decryption(res.data);
          this.recomended_risk = result.recommendation.recommended;
          this.from = result.employee_fund.fund.risk_rating;
          this.select_fund = result?.employee_fund?.fund?.risk_rating;
          this.select_fund_id = result?.employee_fund?.fund?.id;
          this.getAllavailableFunds();
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  onSearch() {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getFundsReallocations();
    }, 800);
  }

  afterList() {
    this.list.forEach((item) => {
      item["delete"] = item.status == "0" ? true : false;
      item["created_at"] = moment(item["created_at"]).format("DD/MM/YYYY");
      item["company_name"] = item?.employers_employee?.employer?.company_name;
      item["from_portfolio"] = item?.from_fund?.name;
      item["to_portfolio"] = item?.fund?.name;
      if (item.status == "1" && item.bank_status == "1") {
        item["statusToShow"] = "Completed";
      } else if (item.status == "3" && item.bank_status == "3") {
        item["statusToShow"] = "UnderProcess";
      } else if (item.status == "1" && item.bank_status == "0") {
        item["statusToShow"] = "Waiting";
      } else if (item.status == "0" && item.bank_status == "0") {
        item["statusToShow"] = "Pending";
      } else if (item.status == "2" && item.bank_status == "0") {
        item["statusToShow"] = "Rejected";
      } else if (item.status == "2" && item.bank_status == "2") {
        item["statusToShow"] = "cancel";
      } else if (item.status == "1" && item.bank_status == "2") {
        item["statusToShow"] = "Declined by Bank";
      }
    });
  }

  actionClicked(event) {
    if (event.action.type == "delete") {
      this.acceptReject(event.element.id, 2);
    }
  }

  setPage(page) {
    this.paginations.page = page;
    this.getFundsReallocations();
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getFundsReallocations();
    }, 800);
  }

  onFilters(event) {
    this.sort_by = event.selected;
    this.getFundsReallocations();
  }
}
