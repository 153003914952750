import { Component, HostListener, OnInit } from '@angular/core';
import { DecEncService } from 'src/app/services/dec-enc';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
declare global {
  interface Window {
    callSuccess: boolean;
  }
}

@Component({
  selector: 'app-asset-holding-report',
  templateUrl: './asset-holding-report.component.html',
  styleUrls: ['./asset-holding-report.component.scss']
})

export class AssetHoldingReportComponent implements OnInit {
  companyListing = 'portfolios';
  LOGIN_KEY = "login_status";
  list: any = [];
  summary: any = [];
  start_date: any;
  body: any;
  employerName: any;
  currentLang: any;
  totalInvestedAmount: any = 0.0000;
  totalPurchasedUnits: any = 0.0000;
  totalWithdrawnUnits: any = 0.0000;
  totalUnitsOnHand: any = 0.0000;
  totalCurrentValue: any = 0.0000;
  totalAbsoluteReturn: any = 0.0000;
  totalReturn: any = 0.0000;
  iBnNo: any;

  constructor(protected dec_enc: DecEncService, protected mainService: ApiService, private datePipe: DatePipe, private translate: TranslateService, private authService: AuthService, private route: ActivatedRoute) {
    this.currentLang = localStorage.getItem('Lang');
    this.employerName = localStorage.getItem('employer_name');
    localStorage.clear();
    this.startCall();
    this.route.queryParams.subscribe(params => {
      const token = params['t'];
      localStorage.setItem('token', token);
      const password = params['p'];
      localStorage.setItem('employee_report_token', password);
      this.start_date = params['sd'];
    });
  }

  ngOnInit(): void {
    this.getIBAN()
    this.getAll();
  }

  capitalizeFirstLetter(word: string): string {
    if (!word) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  getAll() {
    const today = new Date();
    this.body = {
      start_date: this.start_date
    };
    let enc_payload = this.authService.encryption(this.body);
    let url = 'reports/portfolio-holding';
    this.authService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.authService.decryption(res.data);
        this.list = result?.data;
        this.summary = result.summary;
        this.list.forEach(element => {
          this.totalInvestedAmount += Number(element['invested_amount']);
          this.totalPurchasedUnits += Number(element['purchased_units']);
          this.totalWithdrawnUnits += Number(element['withdrawn_units']);
          this.totalUnitsOnHand += element['units_in_hand'];
          this.totalCurrentValue += element['current_value'];
          this.totalAbsoluteReturn += element['absolute_return'];
          this.totalReturn += element['return_percentage'];
          element.return_percentage = parseFloat(element.return_percentage).toFixed(4) + '%';
        });
        if (this.list.length > 0) {
          let total = {
            fund_name: this.translate.instant('Total:'),
            invested_amount: this.totalInvestedAmount.toFixed(4),
            purchased_units: this.totalPurchasedUnits.toFixed(4),
            withdrawn_units: this.totalWithdrawnUnits.toFixed(4),
            units_in_hand: this.totalUnitsOnHand,
            current_value: this.totalCurrentValue,
            absolute_return: this.totalAbsoluteReturn,
            //return_percentage: parseFloat(this.totalReturn).toFixed(4) + '%',
            report: true,
          }
          this.list.push(this.createEmptyRow());
          this.list.push(total)
        }
        window.callSuccess = true;
      }
      else {
        window.callSuccess = false;
        window.errorMessage = res.error.message;
      }
    });
  }

  getIBAN() {
    let url = 'viewProfile';
    this.authService.postData(url, {}).then(res => {
      if (res.statusCode === 200) {
        let result = this.authService.decryption(res.data);
        this.iBnNo = result.user.accounts?.iban_number;
      }
    })
  }

  createEmptyRow() {
    return {
      id: '',
      debit: '',
      credit: '',
      isEmptyRow: true,
    }
  }

  startCall() {
    localStorage.removeItem(this.LOGIN_KEY);
    this.authService.getStart("start").then((res) => {
      if (res.statusCode == 200) {
        localStorage.setItem("password_token", res.data.access_token);
        return;
      }
    });
  }
}