import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { AlertDialog } from "src/app/lib/alert-dialog/alert.dialog";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { MessageService } from "src/app/services/message.service";
import { Direction } from "@angular/cdk/bidi";
import { Subscription } from "rxjs-compat";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { ProfileService } from "../services/profile.service";

@Component({
  selector: "app-template-main-app-new",
  templateUrl: "./template-main.component.html",
  styleUrls: ["./template-main.component.scss"],
})
export class TemplateMainComponent implements OnInit
{
  isMenuHidden = false;
  submenue: any = false;
  menus: any[];
  employee_name: any;
  fund_allocated: any;
  showHideSideMenu: any = false;
  list: any = [];
  dashboard: any = true;
  Count: any = 0;
  oldCount: any = [];
  NotificationIds: any = [];
  afterResponceCount: any = 0;
  fullName: any[] = [];
  NotificationNavigation: any;
  terminationStatus: string;
  notificationsCount: any;
  NotificationType: any;
  employeeName: string;
  isExpanded = false;
  direction: Direction = "ltr";
  isArabic = false;
  mode: any = "side";
  openSidenav: boolean = false;
  mediaConfig: Subscription;
  headerDataSubscription: Subscription;
  templateHeaderData: any;
  Home: "Home";
  routeName = "";
  routerSubscription: Subscription;
  topBarRouteNames = {
    profile: "My Profile",
    "basic-detail": "My Detailed Information",
    manage_portfolio: "My Portfolio",
    "manage-contribution": "Manage Contributions",
    manage_withdrawls: "Manage Withdrawals",
    "reallocate-funds": "Reallocate Portfolios",
    notification: "Announcements",
    maintenance: "Maintenance",
    "activity-notifications": "Activity Notification",
    "risk-appetite-survey": "Risk Appetite Survey",
    "risk-tolerance-calculator": "Return Calculator",
    "help-support": "Support",
    "academy-courses": "Courses",
    course: "Courses",
    "self-onboarding": "Self-Onboarding"
  };

  constructor(
    public tr: TranslationService,
    protected mainservice: ApiService,
    public route: Router,
    private dec_enc: DecEncService,
    protected dialog: MatDialog,
    public messageservice: MessageService,
    public prService: ProfileService,
    private media: MediaObserver
  )
  {
    this.terminationStatus = this.prService.user.termination_status;
    this.mediaConfig = this.media
      .asObservable()
      .subscribe((change: MediaChange[]) =>
      {
        this.openSidenav = !(change[0].mqAlias == "xs");
        if (change[0].mqAlias == "xs")
        {
          this.isExpanded = true;
        } else
        {
          this.isExpanded = false;
        }
      });

    if (localStorage.getItem("lang") == "ar")
    {
      this.direction = "rtl";
      this.isArabic = true;
    } else
    {
      this.direction = "ltr";
      this.isArabic = false;
    }

    this.routerSubscription = route.events.subscribe((event) =>
    {
      if (event instanceof NavigationEnd)
      {
        for (let key in this.topBarRouteNames)
        {
          if (event.urlAfterRedirects.includes(key))
          {
            this.routeName = this.topBarRouteNames[key];
          }
        }
      }
    });
  }

  toggle(mode?: any)
  {
    if (this.mode == "side")
    {
      this.mode = "over";
    } else
    {
      this.mode = "side";
    }
  }

  ngOnDestroy(): void
  {
    this.mediaConfig.unsubscribe();
  }

  ngOnInit(): void
  {
    document.addEventListener("visibilitychange", () =>
    {
      this.notificationCount();
    });
    this.notificationCount();
    this.employeeName = this.prService.user.name;
    this.fullName = this.prService.user.name.split("");
    if (this.fullName.length == 1)
    {
      let name = this.fullName[0].charAt(0);
      this.employee_name = name.toUpperCase();
    } else
    {
      let name =
        this.fullName[0].charAt(0) +
        this.fullName[this.fullName.length - 1].charAt(0);
      this.employee_name = name.toUpperCase();
    }
    if (this.prService.user.survey == "0")
    {
      this.showHideSideMenu = false;
      this.route.navigate(["main/risk-appetite-survey"]);
    } else
    {
      this.showHideSideMenu = true;
    }

    this.tr.selectedApp.subscribe((state) =>
    {
      this.tr.translation = state;
      this.menusCall();
    });
    this.menusCall();
    this.messageservice.receiveMessage();
    this.messageservice.pushMsgRefresh.subscribe((data) =>
    {
      this.NotificationType = data.data.type;
      let body = {
        title: data.data.title,
        message: data.data.body,
        id: data.data.id,
      };
      this.notificationCount();
    });
    this.tr.selectedApiCallAgain.subscribe((state) =>
    {
      this.notificationCount();
    });
  }

  menusCall()
  {
    if (this.prService.user.termination_status == "1")
    {
      this.menus = [
        {
          routerLink: "manage_portfolio",
          icon: "home",
          label: "Manage Portfolio",
        },
      ];
    } else
    {
      this.menus = [
        {
          routerLink: "manage_portfolio",
          icon: "home",
          label: "Manage portfolio",
        },
        {
          routerLink: "manage-contribution",
          icon: "person",
          label: "Manage Contributions",
        },
        {
          routerLink: "manage_withdrawls",
          icon: "account_balance",
          label: "Manage Withdrawal",
        },
        {
          routerLink: "reallocate-funds",
          icon: "local_library",
          label: "Reallocate Portfolios",
        },
        {
          routerLink: "notification",
          icon: "local_post_office",
          label: "Announcements",
        },
        {
          routerLink: "activity-notifications",
          icon: "local_post_office",
          label: "Activity Notification",
        },
        {
          routerLink: "risk-appetite-survey",
          icon: "assignment",
          label: "Risk Appetite Survey",
        },
        {
          routerLink: "risk-tolerance-calculator",
          icon: "input",
          label: "Return Calculator",
        },
        // {
        //   routerLink: "academy-courses",
        //   icon: "import_contacts",
        //   label: "Academy",
        // },
      ];
    }
  }

  profile()
  {
    this.route.navigate(["main/profile"]);
  }

  logout()
  {
    this.mainservice.onLogout().then((result) =>
    {
      if (result.statusCode == 200)
      {
        localStorage.clear();
        localStorage.removeItem("employee_token");
        localStorage.removeItem("employee_password_token");
        localStorage.removeItem("employee_id");
        localStorage.removeItem("termination_status");
        localStorage.removeItem("terms_status");
        window.location.reload();
      }
    });
  }

  onMenuClick(menu, event): void
  {
    localStorage.removeItem("componentSettings");
    event.preventDefault();
    event.stopPropagation();

    this.menus.forEach((element) =>
    {
      if (element.label != menu.label)
      {
        element.opened = true;
      }
    });
    menu.opened = !menu.opened;
    this.submenue = !this.submenue;
  }

  closeMenue()
  {
    this.menus.forEach((element) =>
    {
      element.opened = false;
      this.submenue = false;
    });
  }

  onChildClick(menu, event): void
  {
    event.preventDefault();
    event.stopPropagation();
  }

  onMenuHidden()
  {
    this.menus.forEach((element) =>
    {
      element.opened = false;
    });
    this.isMenuHidden = !this.isMenuHidden;
  }

  onCheckAllocatedFund()
  {
    let url = "funds/allocated";
    this.mainservice.postData(url, {}).then(
      (res) =>
      {
        if (res.statusCode == 200)
        {
          let result = this.dec_enc.decryption(res.data);
          this.fund_allocated = result?.employee_fund?.employers_employee?.user;
          if (this.fund_allocated == null)
          {
            this.showHideSideMenu = false;
            this.route.navigate(["main/risk-appetite-survey"]);
          } else
          {
            this.showHideSideMenu = true;
          }
        }
      },
      (error) =>
      {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = this.tr.translation.error;
        cm.message = this.tr.translation.serverError;
        cm.cancelButtonText = this.tr.translation.okay;
        cm.type = "error";
      }
    );
  }

  myProfile()
  {
    this.route.navigate(["main/basic-detail"]);
  }

  helpSupport()
  {
    this.route.navigate(["main/help-support"]);
  }

  notificationCount(): void
  {
    this.mainservice.postData("home/unread_count", {}).then((response) =>
    {
      if (response.statusCode == 200)
      {
        let result = this.dec_enc.decryption(response.data);
        this.afterResponceCount =
          result.notification_count + result.activity_count;
        this.notificationsCount = this.afterResponceCount;
        if (this.notificationsCount > 99)
        {
          this.notificationsCount = "99+";
        }
        this.NotificationNavigation = result.navigation;
        if (this.Count != this.afterResponceCount)
        {
          if (this.afterResponceCount > this.Count)
          {
            try
            {
              let audio = new Audio();
              audio.src = "./assets/pendingAudio.mp3";
              audio.load();
              audio.muted = false;
              audio.play();
              audio.loop = false;
            } catch (e) { }
          }
          this.Count = result.count;
        }
      }
    });
  }

  countValue()
  {
    if (this.NotificationNavigation == "activity")
    {
      this.route.navigateByUrl("main/activity-notifications");
    } else
    {
      this.route.navigateByUrl("main/notification");
    }
  }
}
