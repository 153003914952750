import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { TranslationComponent } from "./lib/translation/translation";
import { TranslationService } from "./lib/translation/translation.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertDialog } from "./lib/alert-dialog/alert.dialog";
import { ProfileComponent } from "./views/employee/profile/profile.component";
import { ManagePortfolioComponent } from "./views/employee/manage-portfolio/manage-portfolio.component";
import { ManageWithdrawlsComponent } from "./views/employee/manage-withdrawals/manage-withdrawls.component";
import { WithdrawlsFormComponent } from "./views/employee/manage-withdrawals/withdrawls-form.component";
import {
  ManageContributionComponent,
  ContributionViewComponent,
} from "./views/employee/contributions";
import { AuthService } from "./services/auth.service";
import { ApiService } from "./services/api.service";
import { PaginationService } from "./services/pagination.service";
import { DecEncService } from "./services/dec-enc";
import { ApiLoaderComponent } from "./lib/api-loader/api-loader.component";
import { ApiLoaderService } from "./lib/api-loader/api-loader.service";
import { ApiLoaderInterceptorService } from "./lib/api-loader/api-loader.interceptor.service";
import { WithdrawlsViewComponent } from "./views/employee/manage-withdrawals/withdrawls-view.component";
import { ReallocateFundComponent } from "./views/employee/reallocate-fund/reallocate-fund.component";
import {
  RiskChartComponent,
  RiskServayComponent,
  RiskScreenComponent,
} from "./views/employee/risk-servay";
import { ChartModule } from "angular-highcharts";
import { ChartsModule } from "ng2-charts";
import { ReturnCalculatorComponent } from "./views/employee/return-calculator/return-calculator.component";
import { DatePipe, DecimalPipe } from "@angular/common";
import { MessageService } from "./services/message.service";
import { TpNotificationsComponent } from "./views/employee/tp-notifications/tp-notifications.component";
import { ActivityNotificationsComponent } from "./views/employee/activity-notifications/activity-notifications.component";
import { TokenService } from "./lib/token/token.service";
import { HelpSupportComponent } from "./views/employee/help-support/help-support.component";
import { TranslationNewComponent } from "./lib/translation-new/translation-new.component";
import { LoginComponent } from "./views/auth/login/login.component";
import { AlertDialogNew } from "./lib/alert-dialog-new/alert.dialog.new";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgOtpInputModule } from "ng-otp-input";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { ForgotPasswordComponent } from "./views/auth/forgot-password/forgot-password.component";
import { CreateNewPasswordComponent } from "./views/auth/create-new-password/create-new-password.component";
import { TemplateMainComponent } from "./templates/template-main.component";
import { CardComponent } from "./lib/card/card.component";
import { TableComponent } from "./lib/table/table.component";
import { TPPaginationComponent } from "./lib/tp-pagination/pagination.component";
import { EmployeeFormNewComponent } from "./views/employee/profile/employee-form-new/employee-form-new.component";
import { TermsComponent } from "./views/employee/terms_conditions/terms.component";
import { ProfileService } from "./services/profile.service";
import { AcademyComponent } from "./views/employee/academy/academy.component";
import { FormatTime } from "./lib/table/format-time.pipes";
import { LessonsComponent } from "./views/employee/academy/lessons/lessons.component";
import { VideoDialog } from "./views/employee/academy/video-dialog/video-dialog";
import { NgxYoutubePlayerModule } from "ngx-youtube-player";
import { MaintenanceComponent } from './views/employee/maintenance/maintenance.component';
import { ToastrModule } from "ngx-toastr";
import { WelcomeComponent } from './views/auth/welcome/welcome.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { RegisterCompleteDialogComponent } from './views/auth/register/register-complete-dialog/register-complete-dialog.component';
import { SelfOnboardingComponent } from "./views/auth/self-onboarding/self-onboarding.component";
import { EmployeeSavingProgramComponent } from "./views/auth/employee-saving-program/employee-saving-program.component";
import { OtpComponent } from "./views/employee/manage-withdrawals/otp/otp.component";
import { TransactionReportComponent } from "./views/auth/transaction-report/transaction-report.component";
import { AssetHoldingReportComponent } from "./views/auth/asset-holding-report/asset-holding-report.component";
import { CongratulationsDialogComponent } from "./views/employee/congratulations-dialog/congratulations-dialog.component";
import { BenifitPlanComponent } from "./views/auth/benifit-plan/benifit-plan.component";
import { HrPolicyComponent } from "./views/auth/hr-policy/hr-policy.component";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TranslationComponent,
    TemplateMainComponent,
    ForgotPasswordComponent,
    CreateNewPasswordComponent,
    ProfileComponent,
    ManagePortfolioComponent,
    AlertDialog,
    AlertDialogNew,
    ManageWithdrawlsComponent,
    WithdrawlsFormComponent,
    ManageContributionComponent,
    SelfOnboardingComponent,
    ApiLoaderComponent,
    WithdrawlsViewComponent,
    ContributionViewComponent,
    RiskServayComponent,
    RiskScreenComponent,
    RiskChartComponent,
    ReallocateFundComponent,
    ReturnCalculatorComponent,
    RiskChartComponent,
    EmployeeFormNewComponent,
    TpNotificationsComponent,
    HelpSupportComponent,
    ActivityNotificationsComponent,
    TranslationNewComponent,
    CardComponent,
    TableComponent,
    TPPaginationComponent,
    TermsComponent,
    AcademyComponent,
    LessonsComponent,
    FormatTime,
    VideoDialog,
    MaintenanceComponent,
    WelcomeComponent,
    RegisterComponent,
    RegisterCompleteDialogComponent,
    EmployeeSavingProgramComponent,
    OtpComponent,
    TransactionReportComponent,
    CongratulationsDialogComponent,
    AssetHoldingReportComponent,
    BenifitPlanComponent,
    HrPolicyComponent
  ],

  entryComponents: [AlertDialog, VideoDialog],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,

    HttpClientModule,
    ReactiveFormsModule,
    ChartsModule,
    //  HighchartsChartModule
    ChartModule,
    NgOtpInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxYoutubePlayerModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [
    TranslationService,
    TokenService,
    AuthService,
    ApiService,
    PaginationService,
    DecEncService,
    ApiLoaderService,
    DatePipe,
    MessageService,
    DecimalPipe,
    ProfileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiLoaderInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
