<mat-dialog-content>
    <h3 style="font-size: 30px; font-weight: 600; color:#213060; text-align: center;">{{ "OTP Verification" | translate
        }}</h3>
    <p style="text-align: center; color: #687784;">{{ "Please enter the 6-digit code sent to your email." | translate }}
    </p>
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    <div style="margin-top: 10px; text-align: center;">{{ timerValue }}</div>
    <div style="display: flex; justify-content: center; margin-top: 10px;">
        <button (click)="resendOTP()" mat-button style="padding: 0; border: none; outline: none;"
            [ngStyle]="timerRunning ? {'color': 'red'} : {'color': '#212529'}">
            {{ "ResendOTP" | translate }}
        </button>
    </div>
</mat-dialog-content>