<h3 class="form-heading">Employee Saving Program</h3>

<form [formGroup]="Form" *ngIf="!showOtpComponent">
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
            <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field mt-2" fxFlexAlign="center"
                appearance="outline" style="width: 100%;">
                <mat-label>Full Name</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="name">
            </mat-form-field>
        </div>
        <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
            <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field mt-2" fxFlexAlign="center"
                appearance="outline" style="width: 100%;">
                <mat-label>E-Mail Address</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="email">
            </mat-form-field>
        </div>
        <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
            <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field mt-2" fxFlexAlign="center"
                appearance="outline" style="width: 100%;">
                <mat-label>Mobile Number</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="phone">
            </mat-form-field>
        </div>
        <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
            <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field mt-2" fxFlexAlign="center"
                appearance="outline" style="width: 100%;">
                <mat-label>Select Password</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="password">
            </mat-form-field>
        </div>
        <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
            <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field mt-2" fxFlexAlign="center"
                appearance="outline" style="width: 100%;">
                <mat-label>Confirm Password</mat-label>
                <input autocomplete="off" type="text" matInput formControlName="confirm_password">
            </mat-form-field>
            <div fxLayoutAlign="end center" style="margin-top: 10px;">
                <button class="btn-submit mt-2" mat-raised-button (click)="submit()">Submit</button>
            </div>
        </div>
    </div>
</form>

<ng-container *ngIf="showOtpComponent">
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config">
    </ng-otp-input>
    <!-- <div style="margin-top: 10px">{{ timerValue }}</div> -->
    <!-- <button (click)="resendOTP()" mat-button style="padding: 0; border: none; outline: none"
        [ngStyle]="timerRunning?{'color': 'red'} : {'color': '#212529'}">
        {{ "ResendOTP" | translate }}
    </button> -->
</ng-container>