<div class="main pt-1 mrl-20 mt-30" fxLayout="column" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div fxLayout="row wrap" style="gap: 10px;">
        <mat-card fxFlex="30" class="card-selectable" [class.selected-card]="role['tpl-maker']"
            (click)="setRole('tpl-maker', 'tpl-maker')">
            <div style="display: flex; align-items: center; gap: 30px;">
                <img style="width: 35px;" src="assets/new-icons/savings.png" alt="">
                <div style="flex-direction: column; margin-top: 10px;">
                    <h4 class="fw-b" style="margin-bottom: 0 !important;">{{ 'Your Savings (SAR)' | translate }}</h4>
                    <p style="margin-top: 0px !important; font-weight: bold;">{{available_units | number:'1.4-4'}}</p>
                </div>
            </div>
        </mat-card>
        <mat-card fxFlex="30" [class.selected-card]="role['tpl-checker']">
            <div style="display: flex; align-items: center; gap: 30px;">
                <img style="width: 35px;" src="assets/new-icons/savings.png" alt="">
                <div style="flex-direction: column; margin-top: 10px;">
                    <h4 class="fw-b" style="margin-bottom: 0 !important;">{{ 'Company Reward (SAR)' | translate }}</h4>
                    <p style="margin-top: 0px !important; font-weight: bold;">{{companyReward | number:'1.4-4'}}</p>
                </div>
            </div>
        </mat-card>
        <mat-card fxFlex="30" [class.selected-card]="role['tpl-viewer']">
            <div style="display: flex; align-items: center; gap: 28px;">
                <img style="width: 28px;" src="assets/new-icons/unvested.png" alt="">
                <div style="flex-direction: column; margin-top: 10px;">
                    <h4 class="fw-b" style="margin-bottom: 0 !important;">{{ 'Unvested Amount (SAR)' | translate }}</h4>
                    <p style="margin-top: 0px !important; font-weight: bold;">{{Unvested | number:'1.4-4'}}</p>
                </div>
            </div>
        </mat-card>
    </div>

    <div class="mt-10" fxLayout="row wrap" fxFlex="69" fxFlex.xs="100" fxFlex.sm="100">
        <form [formGroup]="Form" fxLayout="row wrap" fxFlex="49" fxFlex.xs="100" fxFlex.sm="100"
            fxLayoutAlign="start end" style="margin-top: 20px">
            <h2 fxFlex="100" style="color: #213060; margin-bottom: 7px">
                {{ "enterWithdrawal" | translate }}
            </h2>
            <div fxFlex="10">
                <div style="font-size: 18px; font-weight: normal; line-height: 40px" matSuffix>
                    {{ "SAR" | translate }}&nbsp;
                </div>
            </div>
            <div fxFlex="89">
                <mat-form-field class="input-field" fxFlexAlign="center" appearance="outline" style="width: 100%">
                    <mat-label style="color: #213060">{{ "enterAmount" | translate }}</mat-label>
                    <div style="font-weight: bold; color: #79C0C2;" [ngClass]="editModes.amount ? 'hide' : 'show'"
                        [style.color]="amount || amount == 0 ? 'black' : 'transparent'"
                        (click)="setMode('amount', true)" (focus)="setMode('amount', true)">
                        <span style="font-size: 14px;">{{amount | number:'1.4-4' | slice:0:-4}}</span>
                        <span style="font-size: 11px;">{{amount | number:'1.4-4' | slice:-4}}</span>
                    </div>
                    <input (input)="restrictDecimalPlaces($event)" type="number" autocomplete="off" matInput
                        formControlName="withdraw_amount" [(ngModel)]="amount"
                        [ngClass]="editModes.amount == true ? 'show' : 'hide-input'"
                        (focusout)="setMode('amount', false)" (focus)="setMode('amount', true)">

                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue('withdraw_amount')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage('withdraw_amount', 'amount')"
                        [matTooltipDisabled]="!getFieldValue('withdraw_amount')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>
            </div>
        </form>
        <div fxLayout="row wrap" fxFlex="49" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="start end">
            <button class="btn btn-ok" (click)="submit()" [disabled]="Form.invalid">
                {{ 'Request Amount' | translate }}
            </button>
        </div>
    </div>

    <div class="mt-10" fxLayout="row wrap" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
        <p fxFlex="100"
            style="color: #79C0C2; font-size: 16px; font-weight: 600; margin-bottom: 5px; margin-top: 10px;">
            {{ 'How your withdrawal works:' | translate }}</p>
        <p style="font-size: 13px; font-weight: 500; margin-bottom: 0px;">
            {{'Based on the requested amount we will
            calculate how many units of your assets need to be sold. Once you
            press' | translate }} <span style="font-weight: 600; color: #61645A;">{{'Request amount' |
                translate}}</span> {{'You will see the breakdown.' | translate }} </p>
        <p style="font-size: 13px; font-weight: 500;"><span style="font-weight: 600; color: red;">{{'Please Note:' |
                translate}}</span>
            {{'the value of your assets will still change until the final withdrawal and the final withdrawal amount may
            be different from your requested amount.' | translate}} </p>
    </div>

    <div *ngIf="this.showFunds" class="mt-10 mb-20" fxLayout="row wrap" fxFlex="100" fxFlex.xs="100" fxFlex.sm="100">
        <mat-card *ngFor="let fund of funds" class="mt-3" fxFlex="60">
            <div fxLayout="column">
                <div style="margin-bottom:-8px;" fxLayout="row wrap" class="justify-content-between" fxFlex="100"
                    fxFlex.xs="100" fxFlex.sm="100">
                    <p>{{'Fund Name' | translate}}</p>
                    <p>{{'Units' | translate}}</p>
                </div>
                <div style="margin-bottom:-8px;" fxLayout="row wrap" class="justify-content-between" fxFlex="100"
                    fxFlex.xs="100" fxFlex.sm="100">
                    <p style="font-size: 15px; font-weight: 600; color:#213060; margin-top: 0px;">{{ fund.asset_name}}
                    </p>
                    <p style="font-size: 15px; font-weight: 600; color:#213060; margin-top: 0px;">{{ fund.withdraw_units
                        | number:'1.4-4'}}</p>
                </div>
            </div>
        </mat-card>
        <div class="mt-3" fxLayout="row wrap" fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign="end start">
            <button class="btn .btn-cancel" (click)="cancel()">
                {{ "Cancel" | translate }}
            </button>
            <button class="btn btn-ok ml-3" (click)="confirm()">
                {{ "Confirm" | translate }}
            </button>
        </div>
    </div>
</div>