import { Component, OnInit } from "@angular/core";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import * as CryptoJS from "crypto-js";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { DecEncService } from "src/app/services/dec-enc";
import { AlertService } from "src/app/services/alert.service";
import { OtpComponent } from "./otp/otp.component";
@Component({
    selector: "app-withdrawls-form",
    templateUrl: "./withdrawls-form.component.html",
    styleUrls: ["./withdrawls-form.component.scss"],
})
export class WithdrawlsFormComponent implements OnInit {
    Form: FormGroup;
    setPassword: FormGroup;
    failureAlert: boolean;
    alertMsg: any = "";
    sub: Subscription;
    id: any;
    path: any;
    hed: any;
    button: any;
    ut;
    list: any = [];
    available_units: any;
    weighted_nav_unit: any;
    navUnitAmount: number = 0;
    lang = 'en';
    numRegex = /^-?\d*[.,]?\d{0,5}$/;
    amount: number = 0;
    withdrawalAmount = 0.0000;
    role = {
        "tpl-maker": false,
        "tpl-checker": false,
        "tpl-viewer": false,
    }
    funds = [];
    editModes = {
        amount: false,
    }

    showFunds: boolean = false;
    constructor(
        public tr: TranslationService,
        protected formbuilder: FormBuilder,
        protected dialog: MatDialog,
        protected _route: ActivatedRoute,
        public alertService: AlertService,
        protected mainService: ApiService,
        public dec_enc: DecEncService,
    ) {
        this.Form = this.formbuilder.group({
            withdraw_amount: [
                "0",
                [
                    Validators.required,
                    Validators.min(1),
                    Validators.pattern(this.numRegex),
                ],
            ],
        });
    }

    ngOnInit(): void {
        this.lang = localStorage.getItem('lang');
        this.setRole('tpl-maker', 'tpl-maker');
        this.getall();
    }

    getValue(name) {
        return this.Form.get(name);
    }

    getFieldValue(field) {
        if (this.Form.get(field).touched) {
            if (this.Form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    openDialog(): void {
        this.dialog.open(OtpComponent, {
            data: { withdraw_amount: this.Form.get('withdraw_amount')?.value, fundValue: this.showFunds },
            disableClose: true
        });
    }

    submit() {
        let url;
        let enc_payload = this.dec_enc.encryption(this.Form.value);
        url = "withdrawals/create";
        if (+this.Form.get("withdraw_amount").value > +this.available_units) {
            this.alertService.alert(
                "Error",
                "You cannot request to withdraw more than the total available amount"
            );
        } else {
            this.mainService
                .postData(url, { enc_payload })
                .then((res) => {
                    if (res.statusCode == 200) {
                        let result = this.dec_enc.decryption(res.data);
                        this.funds = result;
                        this.getall();
                        this.showFunds = true;
                        return;
                    }
                    else {
                        if (this.lang == 'ar') {
                            this.alertService.alert("Error", res.error.message_ar);
                        }
                        else {
                            this.alertService.alert("Error", res.error.message);
                        }
                    }
                }
                )
                .catch((error) => {
                    this.alertService.alert("Error", this.tr.translation.serverError);
                });
        }
    }

    companyReward: any;
    Unvested: any;
    getall() {
        let url = "home/dashboard";
        this.mainService.postData(url, {}).then(
            (res) => {
                if (res.statusCode == 200) {
                    let result = this.dec_enc.decryption(res.data);
                    this.weighted_nav_unit = result.employees_stats?.weighted_nav_unit;
                    this.available_units = result.employee_fund?.available;
                    this.companyReward = result.company_fund?.vested;
                    this.Unvested = result.company_fund?.unvested;
                }
            },
            (error) => {
                this.alertService.alert("Error", "Server Error");
            }
        );
    }

    reset() {
        this.Form.reset();
    }

    checkValidations() {
        if (this.Form.invalid) {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    restrictDecimalPlaces(event: any) {
        const value = event.target.value;
        const regex = /^\d+(\.\d{0,5})?$/;
        if (!regex.test(value)) {
            event.target.value = this.truncateDecimal(value, 5);
        }
    }

    truncateDecimal(value: string, decimalPlaces: number): string {
        const stringValue = value.toString();
        const decimalIndex = stringValue.indexOf('.');
        if (decimalIndex !== -1) {
            const truncatedValue = stringValue.substring(0, decimalIndex + decimalPlaces + 1);
            return truncatedValue;
        }
        return stringValue;
    }

    setMode(control, mode) {
        if (!this.amount) {
            this.amount = 0;
        }
        this.editModes[control] = mode;
    }

    getErrorMessage(control, fieldName, minVal?, maxVal?) {
        if (this.Form.get(control).invalid) {
            if (this.Form.get(control).hasError("required")) {
                return `${fieldName} is a required field.`;
            }
            if (this.Form.get(control).hasError("min")) {
                return `Minimum value for ${fieldName} is 1.`;
            }
            if (control == "amount" && this.Form.get(control).hasError("pattern")) {
                return `${fieldName} should be numbers only and start from 1.`;
            }
        }
    }

    setRole(role, key) {
        this.role['tpl-maker'] = false;
        this.role['tpl-checker'] = false;
        this.role['tpl-viewer'] = false;
        this.role[key] = true;
        // this.Form.get('role').setValue(role);
    }

    cancel() {
        this.showFunds = false;
    }

    confirm() {
        let url;
        let enc_payload = this.dec_enc.encryption(this.Form.value);
        url = "withdrawals/confirm";
        this.mainService.postData(url, { enc_payload }).then((res) => {
            if (res.statusCode == 200) {
                this.openDialog();
                return;
            }
            else {
                if (this.lang == 'ar') {
                    this.alertService.alert("Error", res.error.message_ar);
                }
                else {
                    this.alertService.alert("Error", res.error.message);
                }
            }
        })
            .catch((error) => {
                this.alertService.alert("Error", this.tr.translation.serverError);
            });
    }


}
