import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { ApiService } from 'src/app/services/api.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
@Component({
  selector: 'app-self-onboarding',
  templateUrl: './self-onboarding.component.html',
  styleUrls: ['./self-onboarding.component.scss']
})
export class SelfOnboardingComponent implements OnInit
{
  LOGIN_KEY = "login_status";
  Form: FormGroup;
  constructor(public tr: TranslationService,
    public route: Router,
    protected mainService: ApiService,
    public dec_enc: DecEncService, public formbuilder: FormBuilder, private authService: AuthService, private alertService: AlertService)
  {
    this.startCall();
  }

  ngOnInit(): void
  {
    this.Form = this.formbuilder.group({
      iqama_id: [null, Validators.required],
      // name: [],
    })
  }

  submit()
  {
    if (this.Form.valid)
    {
      let creds = {
        iqama_id: this.Form.get("iqama_id").value,
      };
      let data = {
        enc_password: localStorage.getItem("password_token"),
        enc_payload: this.authService.encryptionAuth(creds),
      };
      let url = 'viq';
      this.authService.auth(url, data).then((res) =>
      {
        if (res.statusCode == 200)
        {
          let dec_data = this.authService.decryptionAuth(res.data)
          localStorage.setItem('employee detail', JSON.stringify(dec_data))
          console.log('Dec~*', dec_data);
          this.route.navigateByUrl('auth/employee-saving-program');
        } else
        {
          this.alertService.alertAr("Error", res);
        }
      });
    }
  }

  startCall()
  {
    localStorage.removeItem(this.LOGIN_KEY);
    this.authService.getStart("start").then((res) =>
    {
      if (res.statusCode == 200)
      {
        localStorage.setItem("password_token", res.data.access_token);
        return;
      }
    });
  }

}



