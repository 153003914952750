<div class="container my-3">
    <div
      style="display: flex; justify-content: space-between;align-items: center; padding-bottom: 10px;border-bottom: 1px solid #79C0C2;">
      <div class="col-md-6 d-flex align-items-center">
        <!-- <img src="./../../../../assets/Tawuniya-logo-1024x317 3.svg" alt="Tawuniya Logo" class="tawuniya-logo me-2"> -->
        <img [src]="companyLogo" alt="Logo" class="tawuniya-logo me-2">
        <div class="vertical-line mx-3"></div>
        <div class="pt-3 text-content">
          <p class="mb-0">Employee Savings</p>
          <p class="pt-1">Program</p>
        </div>
      </div>
      <div class="col-md-6 text-end details-text">
        <p class="mb-0">الشروط والاحكام الخاصة بالموارد الشرية</p>
        <p class="mb-0" style="padding-top: 10px;">HR Terms & Conditions</p>
      </div>
    </div>

    <div class="d-flex justify-content-between flex-wrap form-container"
       style="padding-bottom: 10px; border-bottom: 1px solid #79C0C2;">
        <form [formGroup]="Form" class="d-flex justify-content-between flex-wrap w-100">
            <mat-form-field class="input-field mt-10 form-field" appearance="outline" style="width: 48%;">
                <mat-label>{{ "Date Published" | translate }}</mat-label>
                <input readonly autocomplete="off" type="text" matInput formControlName="datePublished">
            </mat-form-field>
            <mat-form-field class="input-field mt-10 form-field" appearance="outline" style="width: 48%;">
                <mat-label>{{ "Date of user acceptance" | translate }}</mat-label>
                <input readonly autocomplete="off" type="text" matInput formControlName="dateAcceptance">
            </mat-form-field>
        </form>
    </div>
    <div style="padding-top: 20px;">
        <div class="my-3" style="border-bottom: 1px solid #79C0C2;">
            <div class="row mb-3">
                <div class="col-md-6">
                    <div class="left-align">
                        <!-- {{en_text[1]}} -->
                        <div *ngFor="let para of en_text">
                            <p>{{para}}</p>
                            <br>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right-align">
                        <div *ngFor="let para of ar_text">
                            <p>{{para}}</p>
                            <br>
                            
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div class="disclaimer" style="font-size:14px; padding:5px;">
            <p><strong>Disclaimer</strong>: The benefits plan displayed here is subject to change as per the employer's
              policy. Estimations of vesting times are not a guarantee and are contingent upon contributions being made. Plan
              details may change from time to time, and employees may become eligible for additional benefit groups as they
              advance in the company hierarchy levels. Please refer to the HR terms and conditions for more details. </p>
        </div>
        <div class="footer" style="font-size:12px"><strong>© 2024 Thriftplan. All rights reserved.</strong></div>
    </div>
</div>