<div class="container my-3">
  <div
    style="display: flex; justify-content: space-between;align-items: center; padding-bottom: 10px;border-bottom: 1px solid #79C0C2;">
    <div class="col-md-6 d-flex align-items-center">
      <img src="./../../../../assets/Tawuniya-logo-1024x317 3.svg" alt="Tawuniya Logo" class="tawuniya-logo me-2">
      <div class="vertical-line mx-3"></div>
      <div class="pt-3 text-content">
        <p class="mb-0">Employee Savings</p>
        <p class="pt-1">Program</p>
      </div>
    </div>
    <div class="col-md-6 text-end details-text">
      <p class="mb-0">تفاصيل خطة مزايا الموظفين</p>
      <p class="mb-0" style="padding-top: 5px;">Benefits Plan details</p>
    </div>
  </div>

  <div class="d-flex justify-content-between flex-wrap form-container"
    style="padding-bottom: 10px; border-bottom: 1px solid #79C0C2;">
    <form [formGroup]="Form" class="d-flex justify-content-between flex-wrap w-100">
      <mat-form-field class="input-field mt-10 form-field" appearance="outline" style="width: 48%;">
        <mat-label>{{ "Benifit Plan Level" | translate }}</mat-label>
        <input readonly autocomplete="off" type="text" matInput formControlName="designation">
      </mat-form-field>
      <mat-form-field class="input-field mt-10 form-field" appearance="outline" style="width: 48%;">
        <mat-label>{{ "Date of user enrollment" | translate }}</mat-label>
        <input readonly autocomplete="off" type="text" matInput formControlName="date">
      </mat-form-field>
    </form>
  </div>

  <div style="padding-top: 20px;">
    <h3 style="color: #213060;">Your Benifit Plan</h3>
    <div class="my-3">
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="box bg-light-blue left-align">Selected Contribution amount (SAR)</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-gray center-align">SAR {{contributionAmount | number:'1.4-4'}}</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-green right-align">مبلغ المساهمة المختار (رس)</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="box bg-light-blue left-align" style="text-align:left">Contribution Cap</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-gray center-align">SAR {{contributionCap}} </div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-green right-align" style="text-align:right">حد المساهمة المسموح</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="box bg-light-blue left-align">Matching terms</div>
        </div>
        <div class="col-md-4">
          <div *ngIf="fixed" class="box bg-light-gray center-align">
            <strong>{{ matchingType }}</strong> &nbsp;matching&nbsp; <strong>{{ matchingPercent }} %</strong>
          </div>
          <div *ngIf="!fixed" class="box bg-light-gray center-align">
            <strong>Gradually Increase</strong>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-green right-align">شروط المساهمه</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <div *ngIf="fixed" class="box bg-light-blue left-align">Matching %</div>
          <div *ngIf="!fixed" class="box bg-light-blue left-align">Initial Matching %</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-gray center-align">{{matchingPercent}} %</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-green right-align">الحد الاعلى لمساهمة الشركة</div>
        </div>
      </div>
      <div *ngIf="!fixed" class="row mb-3">
        <div class="col-md-4">
          <div class="box bg-light-blue left-align">Number of months till 100%</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-gray center-align">{{monthTillHundred}} </div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-green right-align">عدد الأشهر حتى %100</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="box bg-light-blue left-align">Number of contributions till vesting</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-gray center-align">{{contributionsTillVesting ? contributionsTillVesting : "N/A"}}
          </div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-green right-align">عدد المساهمات لتاريخ الاستحقاق</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="box bg-light-blue left-align">Estimated vesting month</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-gray center-align">{{estimatedMonth ? estimatedMonth : "N/A"}}</div>
        </div>
        <div class="col-md-4">
          <div class="box bg-light-green right-align">الشهر المتوقع للاستحقاق</div>
        </div>
      </div>
    </div>
    <div class="disclaimer" style="font-size:14px">
      <p><strong>Disclaimer</strong>: The benefits plan displayed here is subject to change as per the employer's
        policy. Estimations of vesting times are not a guarantee and are contingent upon contributions being made. Plan
        details may change from time to time, and employees may become eligible for additional benefit groups as they
        advance in the company hierarchy levels. Please refer to the HR terms and conditions for more details. </p>
    </div>
    <div class="footer" style="font-size:12px"><strong>© 2024 Thriftplan. All rights reserved.</strong></div>
  </div>
</div>