import { Component, OnInit } from '@angular/core';
import { DecEncService } from 'src/app/services/dec-enc';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
declare global {
  interface Window {
    callSuccess: boolean;
  }
}
@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})

export class TransactionReportComponent implements OnInit {
  companyListing = 'company';
  LOGIN_KEY = "login_status";
  list: any = [];
  summary: any = [];
  groupName: any = 'All';
  start_date: any;
  end_date: any;
  body: any;
  employeeName: any;
  currentLang: any;
  benificiaryName: any;
  closingBalance: any;

  constructor(protected dec_enc: DecEncService, protected mainService: ApiService, private datePipe: DatePipe, private translate: TranslateService, private authService: AuthService, private route: ActivatedRoute) {
    this.currentLang = localStorage.getItem('Lang');
    localStorage.clear();
    this.startCall();
    this.route.queryParams.subscribe(params => {
      const token = params['t'];
      localStorage.setItem('token', token);
      const password = params['p'];
      localStorage.setItem('employee_report_token', password);
      this.start_date = params['sd'];
      this.end_date = params['ed'];
    });
  }

  ngOnInit(): void {
    this.getBenificiaryName()
    this.getAll();
  }

  capitalizeFirstLetter(word: string): string {
    if (!word) return word;
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  getAll() {
    this.body = {
      start_date: this.start_date,
      end_date: this.end_date,
    };
    let enc_payload = this.authService.encryption(this.body);
    let url = 'reports/employee-transaction';
    this.authService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode === 200) {
        let result = this.authService.decryption(res.data);
        this.summary = result.summary;
        this.list = result?.data;
        this.closingBalance = this.summary.closing_balance + this.summary.unrealize_return;
        this.list?.forEach(element => {
          element['created_at'] = this.datePipe.transform(element['created_at'], 'dd-MM-yyyy');
          element['type'] = this.capitalizeFirstLetter(element['type']);
        });

        window.callSuccess = true;
      }
      else {
        window.callSuccess = false;
        window.errorMessage = res.error.message;
      }
    });
  }

  getBenificiaryName() {
    let url = 'home/planDetails'
    this.authService.postData(url, {}).then(res => {
      if (res.statusCode === 200) {
        let result = this.authService.decryption(res.data);
        this.benificiaryName = result.plan_details.user.employee_name;
      }
      1
    })
  }

  createEmptyRow() {
    return {
      id: '',
      debit: '',
      credit: '',
      isEmptyRow: true,
    }
  }

  startCall() {
    localStorage.removeItem(this.LOGIN_KEY);
    this.authService.getStart("start").then((res) => {
      if (res.statusCode == 200) {
        localStorage.setItem("password_token", res.data.access_token);
        return;
      }
    });
  }
}