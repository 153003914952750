import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";
import { actionButton } from "src/app/lib/table/table.component";
import { TranslationService } from "src/app/lib/translation/translation.service";
import { AlertService } from "src/app/services/alert.service";
import { DecEncService } from "src/app/services/dec-enc";
import { ApiService } from "src/app/services/api.service";
import { PaginationService } from "src/app/services/pagination.service";

@Component({
  selector: "app-manage-withdrawls",
  templateUrl: "./manage-withdrawls.component.html",
  styleUrls: ["./manage-withdrawls.component.scss"],
})
export class ManageWithdrawlsComponent implements OnInit {
  list: any = [];
  totalPages: number;
  pages: any;
  perPage: number = 10;
  index: any = 1;
  currentPage: any = 1;
  count: any;
  searchTimer: any;
  search: any;
  sort_by: any = null;
  sort_order = "";
  sortData: { value: any; key: string }[];
  rejct: any = "assets/ic_cancel_24px.png";
  rejct_dis: any = "assets/ic_cancel.png";
  colHeader = {
    id: "Withdrawal ID",
    employee_id: "Employee ID",
    amount: "Requested Withdrawal Amount (SAR)",
    actual_amount: "Actual Withdrawal Amount (SAR)",
    statusToShow: "Status",
    created_at: "Created Date",
    statusToPaidOFF: "Payoff",
    actions: "Actions",
  };
  columnTypes = {
    amount: "number-4",
    actual_amount: "number-4",
    created_at: "date",
    updated_at: "date",
    statusToShow: "statusToShow",
    statusToPaidOFF: "statusToPaidOFF",
    actions: "actionsSeperate",
  };
  headingData: string = "Manage Withdrawals";
  searchHeading: string = "Search by withdrawal & employee ID";
  headerProps = {
    heading: "Manage Withdrawals",
    hasSearch: true,
    hasButton: true,
    ActionButtons: [
      {
        label: "Create Withdrawal Request",
        route: "manage_withdrawls/add",
        type: "link",
        visibility: true,
        isCustom: false,
        icon: "person_add",
      },
    ],
    filterArray: [
      {
        label: "Sort by",
        type: "filter_list",
        key: "sort_by",
        visibility: false,
        selected: -1,
        options: [
          {
            value: -1,
            label: "All",
            key: "1",
          },
          {
            value: "1",
            label: "Completed",
            key: "1",
          },
          {
            value: "0",
            label: "Pending",
            key: "0",
          },
          {
            value: "2",
            label: "Rejected",
            key: "2",
          },
          {
            value: "3",
            label: "UnderProcess",
            key: "3",
          },
        ],
      },
    ],
  };

  actions: actionButton[] = [
    {
      label: "Cancel Request",
      type: "delete",
      visibility: true,
      isCustom: true,
      color: "#EE5D50",
      background: "rgba(238, 93, 80, 0.05)",
      source: "assets/table-deactivate.svg",
    },
  ];
  paginations = {
    page: 1,
    pages: null,
    per_page: 20,
    count: 0,
  };

  constructor(
    public tr: TranslationService,
    public route: Router,
    public dec_enc: DecEncService,
    protected mainService: ApiService,
    public paginationService: PaginationService,
    public dialog: MatDialog,
    public alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.getall();
  }

  creatred() {
    this.route.navigate(["main/manage_withdrawls/add"]);
  }

  getall() {
    let count = 1;
    if (this.paginations.per_page != null) {
      count = this.paginations.per_page * (this.paginations.page - 1) + 1;
    }
    let data = {
      search: this.search ? this.search : null,
      status: this.sort_by != -1 && this.sort_by != null ? this.sort_by : null,
    };
    let enc_payload = this.dec_enc.encryption(data);
    let url = `withdrawals?per_page=${this.paginations.per_page}&page=${this.paginations.page}`;
    this.mainService
      .postData(url, { enc_payload })
      .then((res) => {
        let result;
        if (res.statusCode == 200) {
          result = this.dec_enc.decryption(res.data);
          this.list = result.withdrawals;
          this.paginations = result.pagination;
          this.afterList();
          return;
        }
        this.alertService.alertAr("Error", res);
      })
      .catch((error) => {
        this.alertService.alert("Error", this.tr.translation.serverError);
      });
  }

  cancleRequest(id) {
    this.alertService
      .alertAsk(
        "Confirmation",
        "Are you sure you want to cancel this request?",
        "yes",
        "no",
        false
      )
      .then((res) => {
        if (res == true) {
          let data = {
            id: id,
          };
          let enc_payload = this.dec_enc.encryption(data);
          let url = "withdrawals/cancel";
          this.mainService
            .postData(url, { enc_payload })
            .then((res) => {
              if (res.statusCode == 200) {
                this.getall();
                return;
              }
              this.alertService.alert("Error", "You have already rejected.");
            })
            .catch((error) => {
              this.alertService.alert("Error", this.tr.translation.serverError);
            });
        }
      });
  }

  afterList() {
    this.list.forEach((item) => {
      if (
        item.employer_status == "0" &&
        item.bank_status == "0" &&
        item.status != "2"
      ) {
        item["delete"] = true;
      }
      item["created_at"] = moment(item["created_at"]).format("DD/MM/YYYY");
      if (item.payoff == "0") {
        item["statusToPaidOFF"] = "Pending";
      } else {
        item["statusToPaidOFF"] = "PaidOff";
      }
      if (item.employer_status == "1" && item.bank_status == "1") {
        item["statusToShow"] = "Completed";
      } else if (item.employer_status == "1" && item.bank_status == "0") {
        item["statusToShow"] = "Waiting";
      } else if (
        item.employer_status == "0" &&
        item.bank_status == "0" &&
        item.status != "2"
      ) {
        item["statusToShow"] = "Pending";
      } else if (item.employer_status == "1" && item.bank_status == "3") {
        item["statusToShow"] = "UnderProcess";
      } else if (item.employer_status == "2" && item.bank_status == "2") {
        item["statusToShow"] = "Declined";
      } else if (item.employer_status == "2" && item.bank_status == "0") {
        item["statusToShow"] = "DeclinedEmployer";
      } else if (item.employer_status == "1" && item.bank_status == "2") {
        item["statusToShow"] = "DeclinedBank";
      } else if (
        item.employer_status == "0" &&
        item.bank_status == "0" &&
        item.status == "2"
      ) {
        item["statusToShow"] = "cancel";
      }
    });
  }

  actionClicked(event) {
    if (event.action.type == "delete") {
      this.cancleRequest(event.element.id);
    }
  }

  setPage(page) {
    this.paginations.page = page;
    this.getall();
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getall();
    }, 800);
  }

  onFilters(event) {
    this.sort_by = event.selected;
    this.getall();
  }
}
