import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/lib/token/token.service';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DecEncService } from 'src/app/services/dec-enc';

@Component({
  selector: 'app-employee-saving-program',
  templateUrl: './employee-saving-program.component.html',
  styleUrls: ['./employee-saving-program.component.scss']
})
export class EmployeeSavingProgramComponent implements OnInit
{
  LOGIN_KEY = "login_status";
  otp: string;
  otpTries = 0;
  userId: any;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "50px",
      height: "50px",
    },
  };
  timer: any;
  timerRunning: boolean = true;
  timerValue: string = "03:00";
  timerOn = true;
  showOtpComponent = false;
  Form: FormGroup;
  constructor(public tr: TranslationService,
    public route: Router,
    protected mainService: ApiService,
    public authorization: TokenService,
    public dec_enc: DecEncService, public formbuilder: FormBuilder, private authService: AuthService, private alertService: AlertService)
  {
    this.startCall();
  }

  ngOnInit(): void
  {
    this.Form = this.formbuilder.group({
      user_id: [],
      name: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null, Validators.required],
      password: [null, Validators.required],
      confirm_password: [null, Validators.required],
    });
    let employee_detail = JSON.parse((localStorage.getItem('employee detail')));
    this.Form.patchValue(employee_detail?.employee)
    this.Form.get('user_id').setValue(employee_detail.employee.id);
    this.userId = employee_detail.employee.id;
  }

  startCall()
  {
    localStorage.removeItem(this.LOGIN_KEY);
    this.authService.getStart("start").then((res) =>
    {
      if (res.statusCode == 200)
      {
        localStorage.setItem("password_token", res.data.access_token);
        return;
      }
    });
  }

  submit()
  {
    if (this.Form.valid)
    {
      let data = {
        enc_password: localStorage.getItem("password_token"),
        enc_payload: this.authService.encryptionAuth(this.Form.value),
      };
      let url = 'ci';
      this.authService.auth(url, data).then((res) =>
      {
        if (res.statusCode == 200)
        {
          let enc_payload = this.authService.decryptionAuth(res.data);
          console.log('Dec~*', enc_payload);

          localStorage.setItem("employee_id", this.userId);
          localStorage.setItem("terms_status", '0');
          localStorage.setItem("termination_status", '0');
          this.showOtpComponent = true;
        } else
        {
          this.alertService.alertAr("Error", res);
        }
      });
    }
  }

  onOtpChange(otp)
  {
    this.otp = otp;
    this.Form.addControl('otp', this.formbuilder.control(null));
    this.Form.get('otp')?.setValue(this.otp);
    if (otp.toString().length == 6 && this.otpTries > 0)
    {
      return;
    }
    if (otp.toString().length < 6)
    {
      this.otpTries = 0;
    }
    if (otp.toString().length == 6 && this.otpTries == 0)
    {
      let data = {
        enc_password: localStorage.getItem("password_token"),
        enc_payload: this.authService.encryptionAuth(this.Form.value),
      };
      this.validate(data);
      this.otpTries++;
    }
  }

  validate(data)
  {
    this.authService.auth("ov", data).then((response) =>
    {
      if (response.statusCode == 200)
      {
        localStorage.removeItem(this.LOGIN_KEY);
        let decrypted = this.authService.decryptionAuth(response.data);
        console.log('Otp Response Data~*', decrypted);
        localStorage.setItem("employee_token", decrypted.access_authorization);
        this.authorization.setToken(decrypted.access_authorization);
        this.authService.token = decrypted.access_authorization;
        localStorage.setItem('isLoggedIn', '1');
        localStorage.setItem(
          "employee_password_token",
          decrypted.password_token
        );
        localStorage.removeItem('employee detail')
        this.route.navigateByUrl("terms");
        // window.location.reload();
      } else
      {
        this.alertService.alertAr("Error", response);
      }
    });
  }

  resendOTP()
  {
    if (!this.timerRunning)
    {
      this.timerRunning = true;
      this.timerOTP(180);
    }
  }

  timerOTP(remaining)
  {
    let m = Math.floor(remaining / 60);
    let s = remaining % 60;

    let min = m < 10 ? '0' + m : m;
    let sec = s < 10 ? '0' + s : s;
    this.timerValue = min + ':' + sec;
    remaining -= 1;

    if (remaining >= 0)
    {
      this.timer = setTimeout(x =>
      {
        this.timerRunning = true;
        this.timerOTP(remaining);
      }, 1000);
      return
    }
    else
    {
      this.timerRunning = false;
    }
  }
}
