import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { Router } from '@angular/router';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  showOtpComponent: boolean = false;
  timerRunning: boolean = true;
  timerValue: string = "03:00";
  withdrawAmount: number;
  timerOn = true;
  otp: string;
  timer: any;
  otpTries = 0;
  config = {
      allowNumbersOnly: false,
      length: 6,
      isPasswordInput: true,
      disableAutoFocus: false,
      placeholder: "",
      inputStyles: {
          width: "50px",
          height: "50px",
      },
  };
  lang = 'en';
  showFunds: boolean;
  constructor(
    public tr: TranslationService,
    protected formbuilder: FormBuilder,
    protected dialog: MatDialog,
    public alertService: AlertService,
    protected mainService: ApiService,
    public dec_enc: DecEncService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OtpComponent>
) {
  this.withdrawAmount = data.withdraw_amount;
  this.showFunds = data.fundsValue;
}

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    this.timerOTP(180);
  }

  resendOTP() {
    if (!this.timerRunning) {
        this.timerRunning = true;
        this.timerOTP(180);
        this.confirm();
    }
}

timerOTP(remaining) {
  let m = Math.floor(remaining / 60);
  let s = remaining % 60;
  let min = m < 10 ? '0' + m : m;
  let sec = s < 10 ? '0' + s : s;
  this.timerValue = min + ':' + sec;
  remaining -= 1;

  if (remaining >= 0) {
      this.timer = setTimeout(x => {
          this.timerRunning = true;
          this.timerOTP(remaining);
      }, 1000);
      return
  }
  else {
      this.timerRunning = false;
  }
}

onOtpChange(otp) {
  this.otp = otp;
  if (otp.toString().length == 6 && this.otpTries > 0) {
      return;
  }
  if (otp.toString().length < 6) {
      this.otpTries = 0;
  }
  if (otp.toString().length == 6 && this.otpTries == 0) {
      let payload = {
          otp: otp,
          withdraw_amount: this.withdrawAmount
      };
      this.validate(payload);
      this.otpTries++;
  }
}

validate(payloadData) {
  let url = "withdrawals/validate";
  let enc_payload = this.dec_enc.encryption(payloadData);
  this.mainService.postData(url, { enc_payload }).then((res) => {
      if (res.statusCode == 200) {
          this.alertService.alert(
            "Success",
            "Your withdrawal request has been sent to Company for approval"
        );
          this.router.navigateByUrl("main/manage_withdrawls");
          this.showFunds = false;
          this.dialogRef.close();
          return;
      }
      else {
          if (this.lang == 'ar') {
              this.alertService.alert("Error", res.error.message_ar);
          }
          else {
              this.alertService.alert("Error", res.error.message);
          }
      }
  })
      .catch((error) => {
          this.alertService.alert("Error", this.tr.translation.serverError);
      });
}

confirm() {
    let url;
    let enc_payload = this.dec_enc.encryption({withdraw_amount: this.withdrawAmount});
    url = "withdrawals/confirm";
    this.mainService.postData(url, { enc_payload }).then((res) => {
        if (res.statusCode == 200) {
            this.alertService.alert("Success", "OTP Resent Successfully.");
            return;
        }
        else {
            if (this.lang == 'ar') {
                this.alertService.alert("Error", res.error.message_ar);
            }
            else {
                this.alertService.alert("Error", res.error.message);
            }
        }
    })
        .catch((error) => {
            this.alertService.alert("Error", this.tr.translation.serverError);
        });
}
}
