<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <div style="display: flex; justify-content: center; align-items: center;">
        <div id="contentToExport" class="main pt-1 mrl-20 mt-10" ngClass.sm="mrl-10" ngClass.xs="mrl-10"
            style="direction: ltr;">
            <table style=" margin:0 auto; background-color:#fff; border-collapse:collapse;">
                <tbody style="margin: 0px 20px !important;">
                    <tr style="display: flex; justify-content: space-between; align-items: flex-end; border-bottom: 1px solid #79C0C2; direction:ltr;"
                        class="mrl-20">
                        <td style="padding-top:20px; text-align:left; flex: 1;" class="top-logo pr-10">
                            <img class="logo" width="215" height="70" src="assets/thrift_logo.png" alt="Logo">
                        </td>
                        <td style="padding-top:20px; text-align: right; font-size:14px; color:#757575; flex: 1;">
                            <h3 class="report-title" style="margin: 0; font-weight: bolder; color: black;">
                                {{'Transaction Report By Employees' | translate}}
                            </h3>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="padding: 10px 20px;">
                            <div class="info" style="display: flex; justify-content: center; ">
                                <div style="flex: 1; font-size: 12px; color: #757575; text-align: left;">
                                    <h5
                                        style="display: flex; justify-content: space-between;color: #000000;font-size: 12px;">
                                        <strong>{{'Report period' | translate}}</strong>
                                        <span style="direction: ltr;unicode-bidi: embed;"> {{start_date | date:
                                            'dd-MM-yyyy'}} -
                                            {{end_date | date: 'dd-MM-yyyy'}}</span>
                                    </h5>

                                    <h5
                                        style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                        <strong>{{'Benificiary name' |translate}}</strong>
                                        <span>{{benificiaryName}}</span>
                                    </h5>
                                </div>
                                <div style="flex: 1; font-size: 12px; color: #757575; text-align: right;">
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                        <strong>{{'Total deposits' | translate}}</strong>
                                        <span>SAR {{(summary?.total_debit ? summary?.total_debit : 0 )|
                                            number:'1.4-4'}}</span>
                                    </h5>
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                        <strong>{{'Total withdrawls' | translate}}</strong>
                                        <span>SAR {{(summary?.total_credit ? summary?.total_credit : 0) |
                                            number:'1.4-4'}}</span>
                                    </h5>
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black; font-size: 12px;">
                                        <strong>{{'Unrealized returns' | translate}}</strong>
                                        <span>{{(summary?.unrealize_return ? summary?.unrealize_return : 0) |
                                            number:'1.4-4'}}</span>
                                    </h5>
                                    <h5
                                        style="display: flex; justify-content: space-between;color: black;font-size: 12px;">
                                        <strong>{{'Closing balance' | translate}}</strong>
                                        <span>SAR {{(closingBalance ? closingBalance : 0) |
                                            number:'1.4-4'}}</span>
                                    </h5>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="3" style="padding: 0 20px;">
                            <table
                                style="width: 100%; border-collapse: collapse; border-bottom: 1px solid #79C0C2; margin-bottom: 20px;">
                                <thead>
                                    <tr style="border-bottom: 1px solid #79C0C2;">
                                        <th style="padding: 10px; text-align: left;font-size:12px">{{ 'Date' | translate
                                            }}
                                        </th>
                                        <th style="padding: 10px; text-align: left;font-size:12px">{{ 'Type' | translate
                                            }}
                                        </th>
                                        <th style="padding: 10px; text-align: left;font-size:12px">{{ 'Description' |
                                            translate }}</th>
                                        <th style="padding: 10px; text-align: left;font-size:12px">{{ 'Debit' |
                                            translate }}
                                        </th>
                                        <th style="padding: 10px; text-align: left;font-size:12px">{{ 'Credit' |
                                            translate
                                            }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let transaction of list;">
                                        <td style="padding: 10px; text-align: left;font-size:11px">{{
                                            transaction.created_at
                                            }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px">{{ transaction.type
                                            }}
                                        </td>
                                        <td style="padding: 10px; text-align: left;font-size:11px">{{
                                            transaction.description }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px">{{ transaction.debit
                                            |
                                            number:'1.4-4' }}</td>
                                        <td style="padding: 10px; text-align: left;font-size:11px">{{ transaction.credit
                                            |
                                            number:'1.4-4'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td *ngIf="list?.length < 1" style="padding: 10px; text-align: center;">
                            <h4><strong>No Record Found</strong></h4>
                        </td>
                    </tr>

                    <tr class="mrl-20">
                        <td colspan="3" style="padding: 0px 20px;">
                            <p style="color: black; font-size: 13px;">{{'This report was generated automatically using
                                data available on Tadawul or supplied by Fund managers at the time of creation. While
                                every effort has been made to ensure accuracy, discrepancies may arise due to the
                                automated nature of the process. This report is intended for informational purposes only
                                and does not constitute financial advice nor is meant for any solicitation of business.
                                The reader should perform their own due diligence or consult with a professional
                                financial advisor before making any investment decisions. The author of this report will
                                not be held responsible for any losses or damages resulting from actions taken
                                based on this report.' | translate}}
                            </p>
                        </td>
                    </tr>

                    <tr class="mrl-20">
                        <td colspan="3" style="padding: 0px 20px;">
                            <p style="color: black;"><strong>Thriftplan.com.sa/Reports</strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>